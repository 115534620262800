require('./bootstrap');


$.ajaxSetup({
   headers: {
       'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
   }
});

function refreshToken(){
    let timestamp=new Date().getTime();
    $.ajax({
        url: "/refresh-token?time="+timestamp,
        type: 'get',
        dataType: 'json',
        success: function (result) {
            
            $('meta[name="csrf-token"]').attr('content', result.token);
            
            $('input[name="_token"]').attr('value', result.token);

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': result.token
                }
            });

            //$('body').append(result.token+'<br/>');
        },
        error: function (xhr, status, error) {
            console.log(xhr);
        }
    });
}

setInterval(function(){
    refreshToken();
},15*60*1000);